import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CustomerSignUpService, FindYourVenueAccordianNames } from './customer-sign-up.service';
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { CognitoService } from '../cognito.service';
import { CustomerProfile, OrganisationDetails, VenueDetails } from 'src/app/Models/Authentication/User';
import { AccordionForFindYourVenue, Markers } from 'src/app/Models/Customer';
import { ActivatedRoute } from '@angular/router';
import { StringHelperService } from 'src/app/core/services/string-helper.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { Marker, MouseEvent } from '@agm/core';
import { Title } from '@angular/platform-browser';
import { CustomerService } from 'src/app/customer/customer.service';
import { AppStartService } from 'src/app/core/services/app-start.service';

@Component({
  selector: 'app-customer-sign-up',
  templateUrl: './customer-sign-up.component.html',
  styleUrls: ['./customer-sign-up.component.css']
})
export class CustomerSignUpComponent implements OnInit {
  customerSignUpForm: FormGroup;
  customerVerifyForm: FormGroup;
  accountVerifyForm: FormGroup;
  packageVerifyForm: FormGroup;
  yourVenueForm: FormGroup;
  organisationDetailsForm: FormGroup;
  showCustomerSignUpForm: boolean = true;
  sawVerifyForm: boolean = false;
  mobileVerifyForm: boolean = false;
  pacakgeSelectionForm: boolean = false;
  findYourVenueForm: boolean = false;
  isVerify: boolean = false;
  isPackageSelected: boolean = false;
  errorMessage: string = '';
  errorMessageConfirm: string = '';
  errorMessageConfirmForMobile: string = '';
  cognitoUser: any;
  cognitoUserId: string = '';
  venueId: string = '';
  groupedPriceData: { [tier: string]: any[] } = {};
  userOptions: { value: string, label: string }[] = [];
  finalSelectedPrice: string =''
  priceData = [];

  lng: number;
  lat: number;
  zoom: number = 14;
  markers: Markers[] = [];
  accordion: AccordionForFindYourVenue = new AccordionForFindYourVenue();
  @ViewChild("search") public searchElementRef: ElementRef<HTMLInputElement>;


  constructor(private fb: FormBuilder, private http: HttpClient, private _customerSignUpService: CustomerSignUpService,
    private _apiErrorService: ApiErrorService,
    private _cognitoService: CognitoService,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute,
    private _stringHelperService: StringHelperService,
    private _googleMapService: GooglemapService,
    public _customerService: CustomerService,
    private _appStartService: AppStartService
  ) { }

  ngOnInit() {
    this.InitForm();
    this._titleService.setTitle(this._activatedRoute.snapshot.data.title);
    this.InitilizeCurrentLocation();
    this.ReadDataFromQueryStringAndOpenTab();
    this.organisationDetailsForm.patchValue({
      country: "UK"
    });
  }

  ngAfterViewInit(): void {
    let timerId = setInterval(() => {
      if (this.searchElementRef){

        this._googleMapService.AutocompleteInitialize(this.searchElementRef.nativeElement, (place) => {
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();

          this.markers[0].lat = this.lat;
          this.markers[0].lng = this.lng;
        }, 
        (addresses, place) => {
          if (addresses && addresses[0]) {
          const address = this._googleMapService.GetPostCodeAndFormattedAddress(addresses);
          
            this.yourVenueForm.patchValue({
              location: place.formatted_address
            });
          } else {
            console.log("Not found");
          }
        });
        clearInterval(timerId);
      }
    }, 1000);
  }

  ReadDataFromQueryStringAndOpenTab() {
    if (!this._activatedRoute.snapshot.paramMap) {
      this.OpenTab(FindYourVenueAccordianNames.FindYourVenueTab);
      return;
    }

    const accordionRouteValue = this._activatedRoute.snapshot.paramMap.get("accordion");
    if (this._stringHelperService.IsNullOrWhiteSpace(accordionRouteValue) || FindYourVenueAccordianNames[accordionRouteValue] === undefined) {
      this.OpenTab(FindYourVenueAccordianNames.FindYourVenueTab);
    }
    else {
      this.OpenTab(accordionRouteValue);
    }
  }

  InitForm() {
    this.customerSignUpForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      organisation: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      mobileNumber: [null, [Validators.required, Validators.pattern(/^\+[1-9]\d{1,14}$/)]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      tickedTerms: [false, Validators.requiredTrue]
    });

    this.customerVerifyForm = this.fb.group({
      code: [null, Validators.required]
    });

    this.accountVerifyForm = this.fb.group({
      mobileCode: [null, Validators.required]
    });

    this.packageVerifyForm = this.fb.group({
      selectedTier: [null, Validators.required],
      selectedUserOption: [null, Validators.required],
      monthPrice: [null, Validators.required],
      yearPrice: [null, Validators.required]
    });

    this.yourVenueForm = this.fb.group({
      location: [null, Validators.required],
    });

    this.organisationDetailsForm = this.fb.group({
      address1: [null, Validators.required],
      address2: [null, Validators.required],
      city: [null, Validators.required],
      county: [null, Validators.required],
      postcode: [null, Validators.required],
      country: [null, Validators.required],
    });
  }

  get firstName() {
    return this.customerSignUpForm.get("firstName") as FormControl;
  }

  get lastName() {
    return this.customerSignUpForm.get("lastName") as FormControl;
  }

  get organisation() {
    return this.customerSignUpForm.get("organisation") as FormControl;
  }

  get email() {
    return this.customerSignUpForm.get("email") as FormControl;
  }

  get mobileNumber() {
    return this.customerSignUpForm.get("mobileNumber") as FormControl;
  }

  get password() {
    return this.customerSignUpForm.get("password") as FormControl;
  }

  get tickedTerms() {
    return this.customerSignUpForm.get("tickedTerms") as FormControl;
  }

  get code() {
    return this.customerVerifyForm.get("code") as FormControl;
  }

  get mobileCode() {
    return this.accountVerifyForm.get("mobileCode") as FormControl;
  }

  get selectedTier() {
    return this.packageVerifyForm.get("selectedTier") as FormControl;
  }

  get selectedUserOption() {
    return this.packageVerifyForm.get("selectedUserOption") as FormControl;
  }

  get monthPrice() {
    return this.packageVerifyForm.get("monthPrice") as FormControl;
  }

  get yearPrice() {
    return this.packageVerifyForm.get("yearPrice") as FormControl;
  }

  get location() {
    return this.yourVenueForm.get("location") as FormControl;
  }

  get address1() {
    return this.organisationDetailsForm.get("address1") as FormControl;
  }

  get address2() {
    return this.organisationDetailsForm.get("address2") as FormControl;
  }

  get city() {
    return this.organisationDetailsForm.get("city") as FormControl;
  }

  get county() {
    return this.organisationDetailsForm.get("county") as FormControl;
  }

  get postcode() {
    return this.organisationDetailsForm.get("postcode") as FormControl;
  }

  get country() {
    return this.organisationDetailsForm.get("country") as FormControl;
  }


  onSubmitCustomerDetails() {
    if (this.customerSignUpForm.invalid) {
      this.customerSignUpForm.markAllAsTouched();
      return;
    }

    const { firstName, lastName, organisation, mobileNumber, email, password } = this.customerSignUpForm.value

    this._cognitoService.CustomerSignUp(
      email, password, {
      email: email,
      first_name: firstName,
      last_name: lastName,
      organisation: organisation,
      mobile_number: mobileNumber
    })
      .then(data => {
        this.sawVerifyForm = true;
        this.showCustomerSignUpForm = false;
        this.mobileVerifyForm = false;
        this.errorMessage = '';
        this.cognitoUser = data.user
        this.isVerify = true
        this.cognitoUserId = data.userSub
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error(error);
      });
  }

  async onResendConfirmationCode() {
    try {
      const { email } = this.customerSignUpForm.value;
      await this._cognitoService.ResendSignUp(email);
    } catch (error) {
      console.error(error);
    }
  }

  onSubmitEmailVerificationDetails() {
    if (this.customerVerifyForm.invalid) {
      this.customerVerifyForm.markAllAsTouched();
      return;
    }

    const { email } = this.customerSignUpForm.value
    const { code } = this.customerVerifyForm.value;
    this._cognitoService.ConfirmSignUp(email, code)
      .then(data => {
        this.onSendMobileVerificationCode()
        this.errorMessageConfirm = '';
      })
      .catch(error => {
        this.errorMessageConfirm = error.message;
      });

  }

  onSendMobileVerificationCode() {
    const { email, password } = this.customerSignUpForm.value
    this._cognitoService.SignInForMobileVerification(email, password)
      .then(async (response) => {
        if (response.signInUserSession.accessToken.jwtToken) {
          this._cognitoService.VerifyCurrentUserAttribute()
          this.sawVerifyForm = false;
          this.showCustomerSignUpForm = false;
          this.mobileVerifyForm = true;
          this.pacakgeSelectionForm = false;
          this.findYourVenueForm = false
        }
      })
      .catch(error => {
        this.errorMessageConfirm = error.message;
      });
  }

  async onResendMobileConfirmationCode() {
    try {
      await this._cognitoService.VerifyCurrentUserAttribute();
    } catch (error) {
      console.error(error);
    }
  }

  onSubmitMobileVerificationDetails() {
    if (this.accountVerifyForm.invalid) {
      this.accountVerifyForm.markAllAsTouched();
      return;
    }

  const { mobileCode } = this.accountVerifyForm.value;
   this._cognitoService.VerifyCurrentUserAttributeSubmit(mobileCode)
     .then(data => {
       this.errorMessageConfirmForMobile = '';
       this.OnCreateUserAndProfileAndOrganisation()
     })
     .catch(error => {
       this.errorMessageConfirmForMobile = error.message;
     });
 }

  OnCreateUserAndProfileAndOrganisation() {
    const { firstName, lastName, organisation, mobileNumber, email, tickedTerms } = this.customerSignUpForm.value
    let customerInfo: CustomerProfile = {
      id: this.cognitoUserId,
      firstName: firstName,
      lastName: lastName,
      organisation: organisation,
      email: email,
      mobileNumber: mobileNumber,
      isPhoneVerified: true,
      isEmailVerified: true,
      tickedTerms: tickedTerms
    }

    this._customerSignUpService.AddUser(customerInfo).subscribe(
      (response) => {
        this._customerSignUpService.GetPrices().subscribe(
          (response) => {
            this.priceData = response.data
            this.pacakgeSelectionForm = true;
            this.findYourVenueForm = false
            this.mobileVerifyForm = false;
            this.sawVerifyForm = false;
            this.showCustomerSignUpForm = false;
            this.isPackageSelected = true
            this.groupPrices();
          },
          (error) => {
            this._apiErrorService.ShowError(error);
          }
        );
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );
  }

  onSubmitPackageDetails() {
    if (this.packageVerifyForm.invalid) {
      this.packageVerifyForm.markAllAsTouched();
      return;
    }

    this.pacakgeSelectionForm = false;
    this.findYourVenueForm = true
    this.mobileVerifyForm = false;
    this.sawVerifyForm = false;
    this.showCustomerSignUpForm = false;
  }

  // For Price Calculation
  groupPrices() {
    this.priceData.forEach(price => {
      const tier = price.lookup_key.split('-')[0];
      if (!this.groupedPriceData[tier]) {
        this.groupedPriceData[tier] = [];
      }
      this.groupedPriceData[tier].push(price);
    });
  }

  onTierSelection(tier: string) {
    this.packageVerifyForm.get('selectedUserOption')?.setValue(null);
    this.packageVerifyForm.get('selectedTier')?.setValue(tier);
    this.updateUserOptions(tier);
    this.updatePrices(tier, this.packageVerifyForm.get('selectedUserOption')?.value);
  }

  updateUserOptions(tier: string) {
    const selectedPrices = this.groupedPriceData[tier] || [];
    const uniqueOptions = new Set<string>();

    selectedPrices.forEach(price => {
      const capacity = price.lookup_key.split('-')[1];
      if (capacity) {
        uniqueOptions.add(capacity);
      }
    });

    this.userOptions = Array.from(uniqueOptions).map(option => ({
      value: option,
      label: `${option} Users`
    }));
  }

  onUserOptionSelection(optionValue: string) {
    this.packageVerifyForm.get('selectedUserOption')?.setValue(optionValue);
    this.updatePrices(this.packageVerifyForm.get('selectedTier')?.value, optionValue);
  }

  updatePrices(tier: string, userOption: string) {
    const selectedPrices = this.groupedPriceData[tier] || [];
    const monthlyPrice = selectedPrices.find(price => {
      const parts = price.lookup_key.split('-');
      return parts.includes(userOption) && parts.includes('monthly');
    });
    const annualPrice = selectedPrices.find(price => {
      const parts = price.lookup_key.split('-');
      return parts.includes(userOption) && parts.includes('annual');
    });

    this.packageVerifyForm.get('monthPrice')?.setValue(monthlyPrice ? `${(monthlyPrice.priceAmount / 100).toFixed(2)}` : null);
    this.packageVerifyForm.get('yearPrice')?.setValue(annualPrice ? `${(annualPrice.priceAmount / 100).toFixed(2)}` : null);
  }

  onPriceButtonClick(priceType: string) {
    const selectedPrice = this.packageVerifyForm.get(priceType)?.value;
    this.finalSelectedPrice = selectedPrice
  }

  // find your venue
  OpenTab(currentTab) {
    let CurrentStatus = this.accordion[currentTab];
    this.allTabClose();
    if (CurrentStatus) {
      this.accordion[currentTab] = false;
    } else {
      this.accordion[currentTab] = true;
    }
  }

  allTabClose() {
    this.accordion.FindYourVenueTab = false;
    this.accordion.OrganisationDetailsTab = false;
  }

  InitilizeCurrentLocation(){
    this._googleMapService.GetCurrentLocation((position: any) => {
      if (!this.lat || !this.lng){
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        const marker = this._customerSignUpService.InstanciateMainRedMarker(this.lat, this.lng, true);
        this.markers[0] = marker;
      }
    }, 
    (addresses: any) => {
      if (addresses && addresses[0]) {
        const address = this._googleMapService.GetPostCodeAndFormattedAddress(addresses);
        // this.yourVenueForm.patchValue({
        //   location: address.formattedAddress
        // });
      } else {
        console.log("Not found");
      }
    });
  }

  markerDragEnd(m: Marker, $event: MouseEvent) {
    this._googleMapService.MarkerDragEnd(m, $event, () => {
      this.lat = $event.coords.lat;
      this.lng = $event.coords.lng;
    }, 
    (addresses) => {
      if (addresses && addresses[0]) {
        const address = this._googleMapService.GetPostCodeAndFormattedAddress(addresses);
        
        this.yourVenueForm.patchValue({
          location: address.formattedAddress
        });

      } else {
        console.log("Not found");
      }
    });
  }

  ContinueFromYourVenueTab() {
    if (this.yourVenueForm.invalid) {
      this.yourVenueForm.markAllAsTouched();
      return;
    }

    let Location = `${ this.lat},${this.lng}`;

    let VenueDetailInfo: VenueDetails = {
      // organisationGroupId: 0,
      location: Location,
    }

    this._customerSignUpService.AddVenueDetails(VenueDetailInfo).subscribe(
      (response) => {
        this.venueId = response.data
        this.OpenTab(FindYourVenueAccordianNames.OrganisationDetailsTab);
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );

  }

  ContinueOrganisationDetailsTab(){
    if (this.organisationDetailsForm.invalid) {
      this.organisationDetailsForm.markAllAsTouched();
      return;
    }

    const { address1, address2, city, county, postcode, country } = this.organisationDetailsForm.value
    let organisationDetailInfo: OrganisationDetails = {
      venueId: this.venueId,
      address1: address1,
      address2: address2,
      city: city,
      county: county,
      postcode: postcode,
      country: country,
    }
    
    this._customerSignUpService.AddOrganisationDetails(organisationDetailInfo).subscribe(
      (response) => {
        this._appStartService.NavigateToDashboard();
      },
      (error) => {
        this._apiErrorService.ShowError(error);
      }
    );
   
  }
}
