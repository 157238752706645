<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="d-flex justify-content-end"><img class="btnMapClose showHand" (click)="closeMapClicked()"
                    src="../../../assets/images/close-black-icon.png"></div>
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                    <form [formGroup]="assetsBuildingForm" (ngSubmit)="onSubmitAssetsBuilding()"
                    class="customer-signup-form">
                    <div class="fieldset">
                        <div class="heading-section">Building / outbuilding
                        </div>
                        <div class="mb-4">
                            <span class="num-circle">1</span>
                            <div>Select the type of building or outbuilding to add.</div>
                            <select class="form-control" formControlName="buildingType" class="w-100 select-box">
                                <option [value]="option.value" *ngFor="let option of buildingOptions">{{option.displayName}}</option>
                            </select>
                            <span class="text-danger text-error-message"
                                *ngIf="buildingType.touched && buildingType.hasError('required')">
                                Building / outbuilding type is required
                            </span>
                            <div class="custom-feild">
                                <div class="label">Does the public have access to this building.</div>
                                <select class="form-control" formControlName="buildingAccess" class="w-100 select-box">
                                    <option [value]="true">Yes</option>
                                    <option [value]="false">No</option>
                                </select>
                            </div>
                            <span class="text-danger text-error-message"
                                *ngIf="buildingAccess.touched && buildingAccess.hasError('required')">
                                Building Access type is required
                            </span>
                            <div class="custom-feild">
                                <div class="label">Number of floors.</div>
                                <select class="form-control" formControlName="numberOfFloors" class="w-100 select-box">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                </select>
                            </div>
                            <span class="text-danger text-error-message"
                                *ngIf="numberOfFloors.touched && numberOfFloors.hasError('required')">
                                Number Of Floors is required
                            </span>
                        </div>
                        <div class="mb-4">
                            <span class="num-circle">2</span>
                            <div>Now, draw on the map where the building or outbuilding is located ▶</div>
                            <span class="text-danger text-error-message" *ngIf="isBuildingMapRequired">
                                Map is required
                            </span>
                        </div>
                        <div class="mb-5">
                            <span class="num-circle">3</span>
                            <div>Add a brief description.</div>
                            <textarea formControlName="buildingDescription" class="form-control textarea-box" rows="4"
                                placeholder="e.g. Locked storage for gas for outside heating." required></textarea>
                            <span class="text-danger text-error-message"
                                *ngIf="buildingDescription.touched && buildingDescription.hasError('required')">
                                Description is required
                            </span>
                        </div>
                    </div>

                    <div class="next-btn">
                        <div class="d-flex flex-column justify-content-between">
                            <p>When you are ready, save your asset.</p>
                            <div class="hs-submit text-start">
                                <button type="submit" class="primary-btn">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
                    <!-- Main map -->
                </div>
                <div class="modal map-modal-style show">
                    <div class="modal-dialog">
                        <div class="modal-content">

                            <div class="modal-body d-flex align-items-center">
                                <div class="body-contain">
                                    <div class="map-iframe" *ngIf="!GetAssetsBuildingInfo.localityMapReinitial">
                                        <agm-map [latitude]="lat"
                                            [longitude]="lng" [zoom]="zoom" (mapReady)="OnMapReadyForBuilding($event)"
                                            [fullscreenControl]='true' [mapTypeControl]='true'>
                                            <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path" [strokeColor]='strokeColor'>
                                            </agm-polygon>
                                            <agm-polygon *ngFor="let path of
                                            buildingTabDetails" [paths]="path">
                                            </agm-polygon>
                                        </agm-map>
                                        <div class="d-flex justify-content-end mt-2">
                                            <button type="button" class="btn btn-danger text-end"
                                                (click)="RemoveBuildingPolygon()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>