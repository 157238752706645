<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="d-flex justify-content-end"><img class="btnMapClose showHand" (click)="closeMapClicked()"
                    src="../../../assets/images/close-black-icon.png"></div>
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                    <form [formGroup]="assetsPerimeterForm" (ngSubmit)="onSubmitAssetsPerimeter()"
                        class="customer-signup-form">
                        <div class="fieldset">
                            <div class="heading-section">Perimeter</div>
                            <div class="mb-4">
                                <span class="num-circle">1</span>
                                <div>Select the type of perimeter to add. You may have a mixture; just start with the
                                    most
                                    common.</div>
                                <select class="form-control" formControlName="perimeterType" class="w-100 select-box">
                                    <option [value]="option.value" *ngFor="let option of perimeterOptions">
                                        {{option.displayName}}</option>
                                </select>
                                <span class="text-danger text-error-message"
                                    *ngIf="perimeterType.touched && perimeterType.hasError('required')">
                                    Perimeter type is required
                                </span>
                            </div>
                            <div class="mb-4">
                                <span class="num-circle">2</span>
                                <div>Now, draw on the map where the fence is located. ▶</div>
                                <span class="text-danger text-error-message" *ngIf="isPerimeterMapRequired">
                                    Map is required
                                </span>
                            </div>
                            <div class="mb-5">
                                <span class="num-circle">3</span>
                                <div>Add a brief description.</div>
                                <textarea formControlName="perimeterDescription" class="form-control textarea-box"
                                    rows="4" placeholder="e.g. fence at the rear of the garden area."
                                    required></textarea>
                                <span class="text-danger text-error-message"
                                    *ngIf="perimeterDescription.touched && perimeterDescription.hasError('required')">
                                    Description is required
                                </span>
                            </div>
                        </div>
                        <div class="next-btn">
                            <div class="d-flex flex-column justify-content-between">
                                <p>When you are ready, save your asset.</p>
                                <div class="hs-submit text-start">
                                    <button type="submit" class="primary-btn">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <!-- Main map -->
                </div>
                <div class="modal map-modal-style show">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body d-flex align-items-center">
                                <div class="body-contain">
                                    <div class="map-iframe" *ngIf="!GetAssetsPerimeterInfo.localityMapReinitial">
                                        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
                                            (mapReady)="OnMapReadyForPerimeter($event)" [fullscreenControl]='true'
                                            [mapTypeControl]='true'>
                                            <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path"
                                                [strokeColor]='strokeColor'>
                                            </agm-polygon>
                                            <agm-polygon *ngFor="let path of
                                      primeterTabDetails" [paths]="path">
                                            </agm-polygon>
                                        </agm-map>
                                        <div class="d-flex justify-content-end mt-2">
                                            <button type="button" class="btn btn-danger text-end"
                                                (click)="RemovePerimeterPolygon()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>