<div tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="d-flex justify-content-end"><img class="btnMapClose showHand" (click)="closeMapClicked()"
                    src="../../../assets/images/close-black-icon.png"></div>
            <div class="custom-container">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                    <form [formGroup]="assetsFurnitureForm" (ngSubmit)="onSubmitAssetsFurniture()"
                    class="customer-signup-form">
                    <div class="fieldset">
                        <div class="heading-section">Street furniture
                            & infrastructure
                        </div>
                        <div class="mb-4">
                            <span class="num-circle">1</span>
                            <div>Select the type of street furniture
                                & infrastructure to add.</div>
                            <select class="form-control" formControlName="furnitureType" class="w-100 select-box">
                                <option [value]="option.value" *ngFor="let option of furnitureOptions">{{option.displayName}}</option>
                            </select>
                            <span class="text-danger text-error-message"
                                *ngIf="furnitureType.touched && furnitureType.hasError('required')">
                                Furniture type is required
                            </span>
                        </div>
                        <div class="mb-4 d-flex gap-4">
                            <div>
                                <img src="../../assets/images/assets_furniture.png" alt="assets_furniture">
                            </div>
                            <div>You only need to include lighting that has an access panel on the front.</div>
                        </div>
                        <div class="mb-4">
                            <span class="num-circle">2</span>
                            <div>Now, draw on the map where the building or outbuilding is located ▶</div>
                            <span class="text-danger text-error-message" *ngIf="isFurnitureMapRequired">
                                Map is required
                            </span>
                        </div>
                        <div class="mb-5">
                            <span class="num-circle">3</span>
                            <div>Add a brief description.</div>
                            <textarea formControlName="furnitureDescription" class="form-control textarea-box" rows="4"
                                placeholder="e.g. Front entrance main light." required></textarea>
                            <span class="text-danger text-error-message"
                                *ngIf="furnitureDescription.touched && furnitureDescription.hasError('required')">
                                Description is required
                            </span>
                        </div>
                    </div>

                    <div class="next-btn">
                        <div class="d-flex flex-column justify-content-between">
                            <p>When you are ready, save your asset.</p>
                            <div class="hs-submit text-start">
                                <button type="submit" class="primary-btn">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
                    <!-- Main map -->
                </div>
                <div class="modal map-modal-style show">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-body d-flex align-items-center">
                                <div class="body-contain">
                                    <div class="map-iframe" *ngIf="!GetAssetsFurnitureInfo.localityMapReinitial">
                                        <agm-map [latitude]="lat"
                                            [longitude]="lng" [zoom]="zoom" [fullscreenControl]='true'
                                            [mapTypeControl]='true'>
                                            <agm-polygon *ngFor="let path of mainPerimeter" [paths]="path" [strokeColor]='strokeColor'>
                                            </agm-polygon>
                                            <agm-marker [latitude]="furnitureTabDetails[0][0]?.lat"
                                                [longitude]="furnitureTabDetails[0][0]?.lng"  [iconUrl]="{url: BLUE_MARKER_URL}" [markerDraggable]="true"
                                                (dragEnd)="markerDragEnd(marker, $event)">
                                            </agm-marker>
                                        </agm-map>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>