import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../core/interceptors/api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public mainPerimeter = [];
  private venueDetails: any;

  constructor(    private _apiService: ApiService) { }
  private activeStepsSubject = new BehaviorSubject<number[]>([]);
  activeSteps$ = this.activeStepsSubject.asObservable();

  setActiveSteps(steps: number[]) {
    this.activeStepsSubject.next(steps);
  }

  setVenueDetails(details: any) {
    this.venueDetails = details;
  }

  getVenueDetailsData() {
    return this.venueDetails;
  }

  SetMainPerimeter(perimeter:any[]){
    localStorage.setItem("perimeter",JSON.stringify(perimeter) );
  }

  RemoveMainPerimeter(key = "perimeter"){
    localStorage.removeItem(key);
  }

  GetMainPerimeter(){
    let obj = localStorage.getItem("perimeter");
    return JSON.parse(obj);
  }

  GetVenueDetails(id: string) {
    const url = `/Venue/${id}`;
    return this._apiService.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
