import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiErrorService } from 'src/app/core/services/api-error.service';
import { GooglemapService } from 'src/app/core/services/googlemap.service';
import { AssetsDetails, GetAssetsFurnitureDTO, GetAssetsPerimeterDTO } from 'src/app/Models/Customer';
import { AssetsService } from 'src/app/customer/components/assets/assets.service';
import { Marker, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-furniture-edit-form',
  templateUrl: './furniture-edit-form.component.html',
  styleUrls: ['./furniture-edit-form.component.css']
})
export class FurnitureEditFormComponent implements OnInit {
  BLUE_MARKER_URL = "../../assets/images/blue-big-marker.png";
  venueId: string;
  assetId: string;
  lng: number;
  lat: number;
  zoom: number = 18;
  strokeColor = "#80cff4"
  isFurnitureMapRequired: boolean = false;
  furnitureOptions = [];
  furnitureTabDetails = [];
  mainPerimeter = []
  assetsFurnitureForm: FormGroup;
  GetAssetsFurnitureInfo = new GetAssetsFurnitureDTO();

  constructor(
    private _dialogRef: MatDialogRef<FurnitureEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _initialInfo: any,
    private _apiErrorService: ApiErrorService,
    private _googleMapService: GooglemapService,
    private _assetsService: AssetsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.InitForm();
    console.log("sdfasdfsdfsdf", this._initialInfo.dialogInfo.allInfo.area);
    
    this.furnitureTabDetails = this._initialInfo.dialogInfo.allInfo.area
    this.lat = this._initialInfo.dialogInfo.lat
    this.lng = this._initialInfo.dialogInfo.lng
    this.mainPerimeter = this._initialInfo.dialogInfo.mainPerimeter
    this.assetsFurnitureForm.get('furnitureType')?.setValue(this._initialInfo.dialogInfo.allInfo.type);
    this.assetsFurnitureForm.get('furnitureDescription')?.setValue(this._initialInfo.dialogInfo.allInfo.description);
    this.furnitureOptions = this._initialInfo.dialogInfo.furnitureOptions
    this.venueId =  this._initialInfo.dialogInfo.venueId
    this.assetId =  this._initialInfo.dialogInfo.allInfo.id
  }

  InitForm() {
    this.assetsFurnitureForm = this.fb.group({
      furnitureType: [null, Validators.required],
      furnitureDescription: [null, Validators.required],
    });
  }

  get furnitureType() {
    return this.assetsFurnitureForm.get("furnitureType") as FormControl;
  }

  get furnitureDescription() {
    return this.assetsFurnitureForm.get("furnitureDescription") as FormControl;
  }

  resetForm(form: FormGroup, defaultValues?: any) {
    if (defaultValues) {
      form.reset(defaultValues);
    } else {
      form.reset();
    }
  }

  commanFurnitureFormSubmission() {
      const newData = {
        localityPosition: [...this.GetAssetsFurnitureInfo?.localityPosition, ...this.furnitureTabDetails],
        ...this.assetsFurnitureForm.value
      };

      const payload = {
        id: this.venueId,
        category: 4,
        type: parseInt(newData.furnitureType),
        description: newData.furnitureDescription,
        area: newData.localityPosition,
        numberFloors: 0,
        hasPublicAccess: false
      } as AssetsDetails
      
      this._assetsService.UpdateAssetsTabsDetails(payload, this.venueId, this.assetId).subscribe(
        (response) => {
          if (response) {
            this.resetForm(this.assetsFurnitureForm);
            this._dialogRef.close(this.assetId);
          }
        },
        (error) => {
          this._apiErrorService.ShowError(error);
        }
      );

    return true;
  }

  onSubmitAssetsFurniture() {
    if (this.assetsFurnitureForm.invalid || (this.furnitureTabDetails.length === 0 && !this.GetAssetsFurnitureInfo?.localityPosition?.length)) {
      this.assetsFurnitureForm.markAllAsTouched();
      this.isFurnitureMapRequired = !this.GetAssetsFurnitureInfo?.localityPosition?.length;
      return false;
    }

    this.isFurnitureMapRequired = false;
    if (!this.commanFurnitureFormSubmission()) {
      return;
    }
  }

  markerDragEnd(m: Marker, $event: MouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.updateFurnitureInfo(this.lat, this.lng);
  }

  updateFurnitureInfo(lat: number, lng: number) {
    if (this.GetAssetsFurnitureInfo.localityPosition == null) {
      this.GetAssetsFurnitureInfo.localityPosition = [];
    }
    this.GetAssetsFurnitureInfo.localityPosition.push([{ lat, lng }]);
  }

  OnMapReadyForFurniture = (map: any) => {
    this._googleMapService.OverlayCompletehandler(map, null, (path) => {
      if (this.GetAssetsFurnitureInfo.localityPosition == null) {
        this.GetAssetsFurnitureInfo.localityPosition = [];
      }
      this.GetAssetsFurnitureInfo.localityPosition.push(this._assetsService.CreatePointArrayForPolygon(path));
    });
  };

  closeMapClicked() {
    this._dialogRef.close();
  }
}

