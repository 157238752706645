export class AccordionForFindYourVenue {
    FindYourVenueTab: boolean = false;
    OrganisationDetailsTab: boolean = false;
}

export class Accordion {
    DrawPerimeterTab: boolean = false;
    VenueTab: boolean = false;
    StartDrawPitchTab: boolean = false;
}

export class GetStartedDTO {
    id: string;
    section60Id: string = '0'
    postcode: string;
    cadNumber: string;
    start: any;
    starttime:any;
    sday: string = "";
    smonth: string = "";
    syear: string = "";

    end: any;
    endtime:any;
    eday: string = "";
    emonth: string = "";
    eyear: string = "";

    locality: any[] = [];
    localityPosition: any[] = [];
    localitySelect: boolean = false;
    localityMapShow: boolean = false;
    localityMapReinitial: boolean = false;

    grounds: string;
    tacticalGrounds: string;
    humanRights: string;
    completedById: string;
    authorisingOfficersId: string;
    approvedStatus: boolean;
    share:boolean;
    download:boolean;
    sectionComplete: boolean;
    incidentId: string;
    uploadedMap: string = "";
    uploadedMapSelect: boolean = false;
    status:string;
    closed:boolean;
}

export class AccordionForAssets {
    PerimeterTab: boolean = false;
    BuildingsOutbuildingsTab: boolean = false;
    ParkingTab: boolean = false;
    StreetFurnitureInfrastructureTab: boolean = false;
}

export class Markers {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
    visible: boolean;
    opacity: number;
    icon?: string;
    ispolygon?:boolean=false;
}

export class GetAssetsPerimeterDTO {
    localityPosition: any[] = [];
    localityMapReinitial: boolean = false;
}

export class GetAssetsBuildingDTO {
    localityPosition: any[] = [];
    localityMapReinitial: boolean = false;
}

export class GetAssetsParkingDTO {
    localityPosition: any[] = [];
    localityMapReinitial: boolean = false;
}

export class GetAssetsFurnitureDTO {
    localityPosition: any[] = [];
    localityMapReinitial: boolean = false;
}

export class VenueDTO {
    id: string
    location: string
    address1: string
    address2: string
    city: string
    county: string
    postcode: string
    country: string
    assets: []
    complete: boolean
    deleted: boolean
    people: []
}

export class AssetsDetails {
    id: string
    category: number
    type: number
    description: string
    area: []
    numberFloors: number
    hasPublicAccess: boolean
}