<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Sign up</title>
</head>

<body>
  <div class="container">
    <div class="d-flex flex-column flex-md-row">
      <div class="header-logo d-flex d-md-none justify-content-center align-content-center" *ngIf="!findYourVenueForm">
        <img src="../../assets/images/sim-logo1.png" alt="sim-logo">
      </div>
      <div *ngIf="!findYourVenueForm">
        <ol class="stepper">
          <li class="stepper-item complete">
            <span class="stepper-counter"></span>
            <span>Create account</span>
            <span class="stepper-line"></span>
          </li>

          <li class="stepper-item" [ngClass]="{'complete': isVerify}">
            <span class="stepper-counter"></span>
            <span>Verify account</span>
            <span class="stepper-line"></span>
          </li>
          <li class="stepper-item" [ngClass]="{'complete': isPackageSelected}">
            <span class="stepper-counter"></span>
            <span>Select package</span>
            <span class="stepper-line"></span>
          </li>
        </ol>
      </div>
      <div class="w-100">
        <div class="header-logo d-none d-md-flex justify-content-center align-content-center"
          *ngIf="!findYourVenueForm">
          <img src="../../assets/images/sim-logo2.png" alt="sim-logo">
        </div>

        <!-- customer sign up form -->
        <div *ngIf="showCustomerSignUpForm">
          <div class="subheading">Create your account</div>
          <form [formGroup]="customerSignUpForm" (ngSubmit)="onSubmitCustomerDetails()" class="customer-signup-form">
            <div class="row">
              <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                <input type="text" id="firstName" formControlName="firstName" placeholder="First name" class="">
                <span class="text-danger text-error-message"
                  *ngIf="firstName.touched && firstName.hasError('required')">
                  First name is required
                </span>
              </div>
              <div class="col-12 col-sm-6">
                <input type="text" id="lastName" formControlName="lastName" placeholder="Last name">
                <span class="text-danger text-error-message" *ngIf="lastName.touched && lastName.hasError('required')">
                  Last name is required
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="text" id="organisation" formControlName="organisation" placeholder="Organization">
                <span class="text-danger text-error-message"
                  *ngIf="organisation.touched && organisation.hasError('required')">Organization is
                  required.
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="email" id="email" formControlName="email" placeholder="Email">
                <span class="text-danger text-error-message" *ngIf="email.touched && email.hasError('required')">Email
                  is
                  required.</span>
                <span class="text-danger text-error-message" *ngIf="email.touched && email.hasError('email')">Invalid
                  email
                  format.</span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="text" id="mobileNumber" formControlName="mobileNumber" placeholder="Phone Number">
                <span class="text-danger text-error-message"
                  *ngIf="mobileNumber.touched && mobileNumber.hasError('required')">Phone number is required.
                </span>
                <span class="text-danger text-error-message"
                  *ngIf="mobileNumber.touched && mobileNumber.hasError('pattern')"> Invalid mobile number (formate
                  example: '+1234567890')
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="password" id="password" formControlName="password" placeholder="Password">
                <span class="text-danger text-error-message"
                  *ngIf="password.touched && password.hasError('required')">Password is required.
                </span>
                <span class="text-danger text-error-message"
                  *ngIf="password.touched && password.hasError('minlength')">Password must be at least
                  8 characters
                  long.
                </span>
              </div>
            </div>
            <div class="checkbox">
              <div class="checkbox-info">
                <input type="checkbox" id="tickedTerms" formControlName="tickedTerms">
                <label for="tickedTerms">By signing up, you agree to our <a href="#">Terms and conditions</a> and <a
                    href="#">Privacy
                    Policy</a>.</label>
              </div>
              <span class="text-danger text-error-message"
                *ngIf="tickedTerms.touched && tickedTerms.hasError('required')">You must agree to the terms and
                conditions.
              </span>
            </div>
            <div class="row" *ngIf="errorMessage">
              <div class="text-danger">{{ errorMessage }}</div>
            </div>
            <button type="submit">Save</button>
          </form>
        </div>

        <!-- Verify your account form -->
        <div *ngIf="sawVerifyForm">
          <div class="subheading">Verify your account</div>
          <form [formGroup]="customerVerifyForm" (ngSubmit)="onSubmitEmailVerificationDetails()"
            class="customer-verify-form">
            <div class="form-group">
              <div class="description">A code has been sent to your email.</div>
            </div>
            <div class="form-group email">
              <div>{{email?.value}}</div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="text" id="code" formControlName="code" placeholder="Code">
                <span class="text-danger text-error-message" *ngIf="code.touched && code.hasError('required')">Code is
                  required.
                </span>
              </div>
            </div>
            <div class="d-flex description">Not received?</div>
            <button mat-raised-button class="green-button" (click)="onResendConfirmationCode()">Send another
              code</button>
            <div class="row" *ngIf="errorMessageConfirm">
              <div class="text-danger">{{ errorMessageConfirm }}</div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" onclick="">Submit</button>
            </div>
          </form>
        </div>

        <!-- Verify your code -->
        <div *ngIf="mobileVerifyForm">
          <div class="subheading">Verify your account</div>
          <form [formGroup]="accountVerifyForm" (ngSubmit)="onSubmitMobileVerificationDetails()"
            class="account-verify-form">
            <div class="form-group description">
              <div>Success - your email is verified.</div>
            </div>
            <div class="form-group description">
              <div>We have sent a code to your mobile</div>
            </div>
            <input class="d-flex" disabled type="text" [placeholder]="mobileNumber.value">
            <br />
            <div class="row">
              <div class="col-12">
                <input type="text" id="mobileCode" formControlName="mobileCode" placeholder="Code">
                <br />
                <span class="text-danger text-error-message"
                  *ngIf="mobileCode.touched && mobileCode.hasError('required')">Code is required.
                </span>
              </div>
            </div>
            <div class="d-flex description">Not received?</div>
            <button mat-raised-button class="green-button" (click)="onResendMobileConfirmationCode()">Send another
              code</button>
            <div class="row" *ngIf="errorMessageConfirmForMobile">
              <div class="text-danger">{{ errorMessageConfirmForMobile }}</div>
            </div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>

        <!-- package Verify form -->
        <div *ngIf="pacakgeSelectionForm">
          <div class="header-logo d-flex justify-content-center align-content-center">
            <!-- <img src="../../assets/images/sim-logo1.png" alt="sim-logo"> -->
          </div>
          <form [formGroup]="packageVerifyForm" (ngSubmit)="onSubmitPackageDetails()" class="package-verify-form">
            <div class="subheading">Select your package</div>
            <!-- First Part -->
            <div class="row">
              <label for="user" class="package-label description">Venue capacity</label>
              <div class="col-12 col-sm-4 mb-3 mb-sm-0">
                <label for="volutaryTier" class="package-label detail-description"><span>Up to</span> 100.</label>
                <button type="button" id="volutaryTier"
                  [ngClass]="{'active': packageVerifyForm.get('selectedTier')?.value === 'volutary'}"
                  (click)="onTierSelection('volutary')" class="text-center normal-btn">
                  Voluntary
                </button>
              </div>
              <div class="col-12 col-sm-4 mb-3 mb-sm-0">
                <label for="standardTier" class="package-label detail-description"><span>Up to</span> 799.</label>
                <button type="button" id="standardTier"
                  [ngClass]="{'active': packageVerifyForm.get('selectedTier')?.value === 'standard'}"
                  (click)="onTierSelection('standard')" class="text-center normal-btn">
                  Standard
                </button>
              </div>
              <div class="col-12 col-sm-4">
                <label for="enhancedTier" class="package-label detail-description"><span>Over</span> 799.</label>
                <button type="button" id="enhancedTier"
                  [ngClass]="{'active': packageVerifyForm.get('selectedTier')?.value === 'enhanced'}"
                  (click)="onTierSelection('enhanced')" class="text-center normal-btn">
                  Enhanced
                </button>
              </div>
              <span class="text-danger text-error-message"
                *ngIf="packageVerifyForm.get('selectedTier')?.touched && packageVerifyForm.get('selectedTier')?.hasError('required')">
                A package tier is required.
              </span>
            </div>
            <!-- Second Part -->
            <div class="row">
              <div class="col-12">
                <label for="user" class="package-label description">Users</label>
                <div class="">
                  <select id="userOptions" formControlName="selectedUserOption"
                    (change)="onUserOptionSelection($event.target.value)" class="form-control custom-select">
                    <option [value]=null disabled selected>Select an option</option>
                    <option *ngFor="let option of userOptions" [value]="option.value">
                      {{option.label}}
                    </option>
                  </select>
                  <span class="text-danger text-error-message"
                    *ngIf="selectedUserOption.touched && selectedUserOption.hasError('required')">
                    Users is required
                  </span>
                </div>
              </div>
            </div>
            <!-- Third Part -->
            <div class="row">
              <div class="col-12 col-sm-6 mb-3 mb-sm-0">
                <label for="user" class="package-label description">Users</label>
                <button type="button" class="normal-btn" (click)="onPriceButtonClick('monthPrice')"
                  [ngClass]="{'active': (packageVerifyForm.get('monthPrice')?.value !== null && packageVerifyForm.get('monthPrice')?.value) === this.finalSelectedPrice}">
                  {{ this.packageVerifyForm.get("monthPrice").value !== null ? ('£' +
                  this.packageVerifyForm.get("monthPrice").value + ' / month'): '- / month'}}
                </button>
                <span class="text-danger text-error-message"
                  *ngIf="monthPrice.touched && monthPrice.hasError('required') || yearPrice.touched && yearPrice.hasError('required')">Price
                  is required.
                </span>
              </div>
              <div class="col-12 col-sm-6">
                <label for="user" class="package-label description">Users</label>
                <button type="button" class="normal-btn" (click)="onPriceButtonClick('yearPrice')"
                  [ngClass]="{'active': (packageVerifyForm.get('yearPrice')?.value !== null && packageVerifyForm.get('yearPrice')?.value) === this.finalSelectedPrice}">
                  {{ this.packageVerifyForm.get("yearPrice").value !== null ? ('£' +
                  this.packageVerifyForm.get("yearPrice").value + ' / year') : '- / year'}}
                </button>
                <p class="detail-description">Save 2 months by paying annually.</p>
              </div>
            </div>

            <!-- Pay Button -->
            <div class="row">
              <div class="col-12">
                <button type="submit" class="pay-button">Pay</button>
              </div>
            </div>
          </form>
        </div>

        <!-- find your venue tabs -->
        <div class="accordion tableaccordian incident-accord get-started find-your-venue-tabs" id="startincident" *ngIf="findYourVenueForm">
          <!--   Find your venue tab -->
          <div class="accordion-item">
            <h2 class="accordion-header with-link" id="incidentheading1">
              <button class="accordion-button" type="button" (click)="OpenTab('FindYourVenueTab')"
                [attr.aria-expanded]="accordion.FindYourVenueTab" aria-controls="incidentaccord1">
                Find your venue
              </button>
            </h2>
            <div id="incidentaccord1" class="accordion-collapse collapse"
              [ngClass]="{'show': accordion.FindYourVenueTab==true}">
              <div class="accordion-body">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">

                    <div class="mt-3">
                      You can use your business name, street name or
                      postcode. You can drag the pin on to your venue.
                    </div>
                    <form [formGroup]="yourVenueForm"
                      class="my-3">
                      <input class="form-control right-padding" formControlName="location" #search id="pac-input"
                        type="text" placeholder="Search for your location">
                        <span class="text-danger text-error-message"
                        *ngIf="location.touched && location.hasError('required')">
                        location is required
                      </span>
                    </form>

                  <!-- Main map -->
                  <div class="modal map-modal-style show custom-map-model-style">
                    <div class="modal-dialog">
                      <div class="modal-content">

                        <div class="modal-body d-flex align-items-center">
                          <div class="body-contain">
                            <div class="map-iframe">
                              <!-- [agmDrawingManager]="drawing" -->
                              <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [zoomControl]="true"
                                [fullscreenControl]='true' [mapTypeControl]='true'>
                                <agm-marker *ngFor="let m of markers; let i= index" [latitude]="m.lat"
                                  [longitude]="m.lng" [visible]="m.visible" [label]="m.label"
                                  [markerDraggable]="m.draggable" (dragEnd)="markerDragEnd(m, $event)"
                                  [iconUrl]="{url: m.icon}">
                                </agm-marker>
                              </agm-map>
                              <!-- <agm-drawing-manager #drawing="agmDrawingManager" [drawingMode]="'circle'" [circleOptions]="{fillColor:'red', radius: 150}"></agm-drawing-manager> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="save-btn">
                    <div class="hs-submit ms-auto me-auto text-start">
                      <button type="button" (click)="ContinueFromYourVenueTab()" class="primary-btn">Save</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  Organisation details tab -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="incidentheading2">
              <button class="accordion-button collapsed" type="button" (click)="OpenTab('OrganisationDetailsTab')"
                [attr.aria-expanded]="accordion.OrganisationDetailsTab">
                Organisation details
              </button>
            </h2>

            <div id="incidentaccord2" class="accordion-collapse collapse"
              [ngClass]="{'show':accordion.OrganisationDetailsTab==true}" aria-labelledby="incidentheading2">
              <div class="accordion-body">
                <div class="form-style w-400 ms-auto me-auto accordianHeight">
                  <div class="mt-3 mb-3">
                    Please check and add your details
                  </div>
                  <form [formGroup]="organisationDetailsForm" (ngSubmit)="onSubmitCustomerDetails()" class="organisation-details-form">
                    <div class="row">
                      <div class="col-12 mb-3 mb-sm-0">
                        <input disabled type="text" [placeholder]="organisation.value" class="disabled">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" id="address1" formControlName="address1" placeholder="Address line 1">
                        <span class="text-danger text-error-message" *ngIf="address1.touched && address1.hasError('required')">
                          Address line 1 is required
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" id="address2" formControlName="address2" placeholder="Address line 2">
                        <span class="text-danger text-error-message"
                          *ngIf="address2.touched && address2.hasError('required')"> Address line 2 is
                          required.
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" id="city" formControlName="city" placeholder="City">
                        <span class="text-danger text-error-message" *ngIf="city.touched && city.hasError('required')">City
                          is required.</span>
                    
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" id="county" formControlName="county" placeholder="County">
                        <span class="text-danger text-error-message"
                          *ngIf="county.touched && county.hasError('required')">county is
                          required.
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" id="postcode" formControlName="postcode" placeholder="Postcode">
                        <span class="text-danger text-error-message"
                          *ngIf="postcode.touched && postcode.hasError('required')">Postcode is
                          required.
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <input type="text" id="country" formControlName="country" placeholder="UK" class="disabled">
                        <span class="text-danger text-error-message"
                          *ngIf="country.touched && country.hasError('required')">country is
                          required.
                        </span>
                      </div>
                    </div>
                    <div class="row" *ngIf="errorMessage">
                      <div class="text-danger">{{ errorMessage }}</div>
                    </div>
                    <div class="save-btn">
                      <div class="hs-submit ms-auto me-auto text-start">
                        <button type="button" (click)="ContinueOrganisationDetailsTab()" class="primary-btn">Save</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>

</html>